.routes-auth {
    display: flex;
    height: 100%;
    flex-direction: column;

    .content {
        display: grid;
        grid-template-columns: 3fr minmax(300px, 2fr);
        flex: 1;
        
        .presentation {
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(#000, #5271ff);
            
            img {
                width: 90%;
            }
        }

        .form {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.component-ui-loadingscreen {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#273058, #5271ff);

    > div {
        width: 40%;
        text-align: center;
    }

    img {
        margin-top: -70px;
    }

    p {
        color: #fff;
        margin-top: -100px;
    }
}
.parlans-message {
    background-color: #465cc0;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    
    .sticker {
        width: 100%!important;
        max-width: 250px;
        &:hover {
            opacity: 1 !important; 
            cursor: unset!important;
        }
    }
}
.components-ui-chat {
	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: #5271ff;

	.messages {
		display: flex;
		flex-direction: column-reverse;
		flex: 1;
		padding: 10px;
		overflow-y: auto;
		width: 100%;
	}

	.message-input {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		background-color: #95a9ff;
		padding: 10px 5px;

		input {
			width: 100%;
			resize: none;
			padding: 5px;
			font-family: sans-serif;

			&:focus {
				outline: none;
			}
		}

		button {
			color: #fff;
			width: 80px;
			font-size: 1em;
			background-color: #5271ff;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}

		* {
			height: 100%;
			border: 0px;
		}

		*:first-child {
			border-radius: 5px 0px 0px 5px;
		}

		*:last-child {
			border-radius: 0px 5px 5px 0px;
			color: #fff;
			padding: 10px 0px;
			transition-duration: 0.5s;

			&:hover {
				opacity: 0.7;
			}
		}
        
        .is-selected {
            background-color: #7991ff;
            border-bottom: 2px solid #ffffff;
        }
	}

	.react-tabs {
		width: 100%;
	}

	.sticker {
		align-self: center;
		width: 100%;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
}

.components-meetings-meeting {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;

    .video {
        border-radius: 5px;
        flex: 1;
        height: 100%;
        overflow: hidden;
        background-color: #222;
        
        .video-tile {
            width: 100%;
            height: 100%;
            flex: 1;
        }
    }

    .controls {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px

    }
}


li[data-testid="volume-control"]:hover {
    button {
        background-color: unset!important;
    }
}
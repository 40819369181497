.components-card-category-card {
    .opacity {
        display: flex;
        align-items: center;
        justify-content: center;
       
        .name {
            font-size: 2rem;
            text-align: center;
        }
    }

    .see-more {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 1rem;
        opacity: 0.7;
    }
}
.component-form-auth {
    width: 90%;
    padding: 10px;
    max-width: 500px;
    
    h1, p {
        text-align: center;
    }

    > div {
        margin-bottom: 15px;
    }

    .inline-inputs {
        display: flex;
        justify-content: space-between;

        .field-error-group {
            width: 100%;
            &:nth-child(1) {
                margin-right: 5px;
            }

            &:nth-child(2) {
                margin-left: 5px;
            }
        }
    }

    input {
        width: 100%;
        margin: 15px 0px;
        padding: 18px;
        border-radius: 100px;
        border: 0px;
        font-family: 'Montserrat', sans-serif;
        font-size: 0.8em;
        box-shadow: 0px 5px 12px -1px rgba(106, 133, 255, 0.55);
    }

    button[type="submit"] {
        display: block;
        width: 50%;
        padding: 10px;
        margin: auto;
        border: 0px;
        border-radius: 5px;
        background-color: #5271FF;
        color: #fff;
    }

    button[type="button"] {
        margin: 20px auto;
        display: block;
        background-color: transparent;
        border: 0;
    }

    button:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}
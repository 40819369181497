.components-meetings-empty-meeting-screen {
    display: flex;
    flex-direction: column;
    background-color: #222;
    flex: 1;
    border-radius: 5px;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    img {
        height: 50%;
    }

    p {
        font-size: 1.5rem;
        color: #fff;
    }

    button {
        background-color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px;
        font-size: 1.5rem;
        color: #222;
        margin-top: 10px;
        
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }

    .loading {
        color: #ff8000;
    }

    .joined {
        color: #00ff00;
    }

    .left {
        color: #ff0077;
    }
}
.components-ui-left-menu {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #5271ff;
    
    a {
        display: block;
        color: #fff;
        text-decoration: none;
        margin: 20px 0px;
        &:hover {
            opacity: 0.6;
            cursor: pointer;
        }
    }
}
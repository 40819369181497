.components-navigation-top-navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px;

    .buttons {
        margin-right: 25px;
        
        button {
            margin-right: 20px;
        }
    }

    .user {
        display: flex;
        align-items: center;

        .text {
            margin-right: 20px;
        }

        .name {
            font-size: 1.4rem;
            margin-bottom: -5px;
        }
        
        .email {
            font-size: 1.2rem;
            opacity: 0.7;
        }
    }

    .img {
        img {
            width: 3.5rem;
            height: 3.5rem;
        }
    }
}
.app-home {
    height: 100%;
    
    .categories {
        height: 100%;
        padding: 10px;

        .categories-title {
            color: #425fa8;
            margin-bottom: 5px;
        }
    }
}
.message-stickers {
	max-height: 1000px;
	transition: max-height 0.7s ease-out;
	width: 100%;

	&.hidden {
		max-height: 0px;
	}

	.message-sticker-tabs {
		display: flex;
		justify-content: space-evenly;
		
		li {
			list-style-type: none;
			cursor: pointer;
			flex-grow: 1;
			text-align: center;
			border-color: #7991ff;
			border-bottom: 2px solid #7991ff;
			color: #ffffff;
			padding: 0.5rem 0rem ;
		}
	
		.is-selected {
			background-color: #7991ff;
			border-bottom: 2px solid #ffffff;
			transition-duration: 0.5s;
		}
	
	}
	
	.message-stickers {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		width: 100%;
		grid-gap: 1rem;
		max-height: 350px;
		min-height: 350px;
		padding: 10px;
		overflow-y: auto;
		background-color: #7991ff;
	
		&:not(.is-selected) {
			display: none !important;
		}
	}
}
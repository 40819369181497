.components-cards-image-base {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    transition: opacity 0.4s ease;

    .opacity {
        position: relative;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 10px;
        color: #fff;
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
}
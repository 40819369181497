.components-layout-event-categories-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 80%;
    gap: 10px;
    
    .conferences {
        grid-area: 1 / 2 / span 1 / span 2;
    }

    .concerts {
        grid-area: 2 / 1 / span 2 / span 3;
    }
    
    .private-events {
        grid-area: 2 / 4 / span 2 / span 1;
    }
}

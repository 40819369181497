.app-polls {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .manager {
        padding: 25px;
    }
    .title {
        font-size: 1.3em;
    }

    .votable:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    input {
        width: 100%;
        padding: 5px;
        margin-bottom: 5px;
    }

    button[type="submit"] {
        width: 100%;
        padding: 10px;
    }

    p {
        margin-bottom: 5px;
    }

    .poll-options {
        padding-left: 10px;
    }

    .add-option {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        input {
            margin-bottom: unset;
        }

        button {
            padding: 5px;
        }
    }

    .poll-option {
        width: 100%;
        background-color: #5271ff;
        border-radius: 5px;
        margin-bottom: 5px;
        padding: 5px;
        color: #fff;
    }

    .elevated {
        height: 100%;
        width: 100%;
        background-color: #FFFFFF;
        padding: 15px;
        border-radius: 10px;
        -webkit-box-shadow: 0px 3px 11px -3px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 3px 11px -3px rgba(0,0,0,0.3);
        box-shadow: 0px 3px 11px -3px rgba(0,0,0,0.3);
    }

    .finish-poll-btn {
        width: 100%;
        padding: 10px;
        background-color: rgb(228, 62, 62);
        color: #fff;
        border: 0px;
        border-radius: 5px;

        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
}